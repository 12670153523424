import { Typography, CardMedia, makeStyles, Box } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    display: "flex",
    alignItems: "start",
    minHeight: "280px",
    width: "386px",
    padding: "16px",
    justifyContent: "start",
    flexDirection: "column",
    margin: "auto",
  },
  box: {
    width: "100%",
  },
}))

const Short = ({ title, youtube }) => {
  const classes = useStyles()
  return (
    <CardMedia className={classes.wrapper} border="1px solid white">
      <Box className={classes.box}>
        <Typography
          variant="h3"
          color="primary"
          style={{
            marginBottom: "16px",
            fontWeight: "700",
            filter: "drop-shadow(2px 2px 4px #000)",
          }}
        >
          {title}
        </Typography>
        {youtube && (
          <iframe
            width="100%"
            style={{ aspectRatio: "16/9" }}
            // className={classes.videoReel}
            src={youtube}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        )}
      </Box>
    </CardMedia>
  )
}

export default Short
