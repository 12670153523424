import { Typography, Grid, Box, Button, makeStyles } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
// import LayoutProductions from "../../components/layout/layout-productions"
// import { Project } from "../../components/productions/project"
// import Fade from "react-reveal/Fade"
// import { Link } from "react-scroll"
import JobShortsHeroImage from "../images/job-shorts-header.jpg"
import LayoutHome from "../components/layout/layout-home"
import JobShortContainer from "../components/job-short/job-short-container"

const useStyles = makeStyles(theme => ({
  heroContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: "3",
    width: "100%",
    padding: theme.spacing(4),
    paddingTop: "88px",
    height: "100%",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 76.15%, rgba(0, 0, 0, 0.4) 78.62%, rgba(0, 0, 0, 0.4) 78.62%, rgba(0, 0, 0, 0.4) 81.44%, #000000 100%)",
    // [theme.breakpoints.down("sm")]: { height: "90vh" },
  },
  heroDescription: {
    maxWidth: "700px",
    margin: "32px 0",
    [theme.breakpoints.down("sm")]: { fontSize: "14px", margin: "24px 0" },
  },
}))

const JobShorts = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      strapi {
        jobShort {
          title
          JobCategories {
            Jobs {
              JobTitle
              YoutubeUrl
            }
            CategoryTitle
          }
        }
      }
    }
  `)

  return (
    <LayoutHome mainPage>
      <Box color="#999" style={{ paddingTop: "120px", overflow: "hidden" }}>
        <Box height="40vh" position="relative">
          <img
            src={JobShortsHeroImage}
            alt="logo"
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
          />
          <Box className={classes.heroContentWrapper}>
            <Typography
              variant="h1"
              align="center"
              className={classes.heroDescription}
            >
              {data.strapi.jobShort.title}
            </Typography>
          </Box>
        </Box>
      </Box>
      <JobShortContainer
        jobShortCategories={data.strapi.jobShort.JobCategories}
      />
    </LayoutHome>
  )
}

export default JobShorts
