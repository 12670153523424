import { Typography, Box, makeStyles } from "@material-ui/core"
import React from "react"
import Short from "./job-short"

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
  },
  shortContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const ShortsContainer = ({ jobShortCategories }) => {
  const classes = useStyles()
  return (
    <Box>
      {jobShortCategories.map(category => {
        return (
          <>
            <Box
              key={category.CategoryTitle}
              my={8}
              display="flex"
              alignItems="start"
              flexDirection="column"
              margin="auto"
              maxWidth="1200px"
              paddingX="20px"
            >
              <Typography variant="h2" style={{ marginBottom: "32px" }}>
                <span
                  style={{
                    backgroundColor: "#f26522",
                    padding: "0 8px",
                  }}
                >
                  {category.CategoryTitle}
                </span>
              </Typography>
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="start"
                marginTop="50px"
                marginLeft="-16px"
              >
                {category.Jobs.map(({ JobTitle, YoutubeUrl }) => (
                  <Box key={JobTitle} className={classes.shortContainer}>
                    <Short title={JobTitle} youtube={YoutubeUrl} />
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        )
      })}
    </Box>
  )
}

export default ShortsContainer
